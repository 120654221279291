.products{
  &__items{
    @screen lg{
      min-height: 120px;
    }
    &--image{
      width: 100px;
      height: 100px;
      @screen lg{
        width: 120px;
        height: 120px;
      }
    }
    &--content{
      width: calc(100% - 100px);
    }
    .unit-price{
      @screen lg{
        min-width: 57px;
      }
    }
    &--quantity{
      input{
        width: 50px;
        line-height: 16px;
      }
    }
  }
}