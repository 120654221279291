@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

$c-yellow: #E6AA41;
$c-blue: #00303C;
$c-pink: #CE967A;
$c-green: #C1E28D;

.bg-yelloy{
  background: $c-yellow;
}
.bg-blue{
  background: $c-blue;
}
.bg-pink{
  background: $c-pink;
}
.bg-green{
  background: $c-green;
}

body{
  font-family: 'Nunito';
  @apply text-slate-700;
}

.btn{
  &--dark{
    @apply  text-white py-2 px-10 rounded-md w-full;
    background-color: $c-blue;
  }
}
header{
  .btn{
    width: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.main-section{
  background-color: #F3F4F6;
  min-height: calc(100vh - 120px);
}

.tab{
  &__header{
    &--item{
      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        transition: all .3s;
        background-color: black;
      }
      &.active{
        @apply text-slate-700;
        &::after{
          width: 100%;
          transition: all .3s;
        }
      }
    }
  }
  &__body{
    display: none;
    &.active{
      display: block;
    }
  }
}

.order-item{
  @screen lg{
    flex-basis: calc(50% - 15px);
    width: calc(50% - 15px);
    &:nth-child(odd){
      margin-right: 30px;
    }
  }
}
.order-list-products{
  li{
    &:nth-child(even){
      background-color: white;
    }
  }
}