.nav{
  &__menu{
    .active{
      li{
        @apply border-l-4 border-l-indigo-400 bg-indigo-50;
        @screen lg{
          @apply border-b-2 border-b-indigo-400 border-l-0 border-l-transparent;
        }
      }
    }
  }
}